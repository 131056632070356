export const postFixInUrl = {
    // added in FISH-3676
    GREATER_OR_NULL: '.greaterThanOrNull',
    LESS_OR_NULL: '.lessThanOrNull',
    EQUAL_OR_NULL: '.equalsOrNull',
    CONTAINS_OR_NULL: '.containsOrNull',
    EXACT_OR_NULL: '.equalsOrNull',
    STARTS_WITH_OR_NULL: 'equalsOrNull',

    GREATER_EQUAL: '.greaterThanOrEqual',
    LESS_EQUAL: '.lessThanOrEqual',
    GREATER: '.greaterThan',
    LESS: '.lessThan',
    EXACT: '.equals',
    IN: '.in',
    NOT_EMPTY: '.specified',
    NOT_IN: '.notIn',
    NOT_EQUALS: '.notEquals',
    DOES_NOT_CONTAIN: '.doesNotContain',
    /* unimplemented?? */
    CONTAINS: '.equals',
    STARTS_WITH: '.equals',
    /** '': '.equals', */
    // NOT_EXACT: ??
};

export default (key) => {
    const splitBy__ = key.split('__');
    if (splitBy__.length > 2) {
        throw Error(`Multiple ___s found in searchField name: ${key}. (If this is intentional):,
        multiple searchTypes on the same field has not been implemented.`);
    } else if (splitBy__.length === 1) {
        return `${splitBy__[0]}${postFixInUrl.EXACT}`;
    }
    // we found a mapping here.
    const urlPostFix = postFixInUrl[splitBy__[1]];
    if (urlPostFix) {
        return `${splitBy__[0]}${urlPostFix}`;
    }
    throw new Error(`postfix not found: ${splitBy__[1]} on field ${splitBy__[0]}`);
};
